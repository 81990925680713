import { Box, useColorModeValue } from "@chakra-ui/react";
import { motion, useCycle } from "framer-motion";
import React from "react";
import RouterLink from "../DefinitionComponents/Utility/Routing/RouterLink";
import { MenuToggle } from "./MenuToggle";

const MotionBox = motion(Box);

export default function MobileMenu({ links, linkKey, links1, linkKey1 }) {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const bgColor = useColorModeValue(
    "var(--chakra-colors-brand-blue)",
    "var(--chakra-colors-brand-black)"
  );
  const linkColor = useColorModeValue(
    "var(--chakra-colors-brand-lightgray)",
    "var(--chakra-colors-brand-lightgray)"
  );
  const MotionBox = motion(Box);

  const sidebar = {
    open: {
      clipPath: `circle(200vw at 56px 56px)`,
      transition: {
        duration: 0.2,
        staggerChildren: 0.05,
        when: "beforeChildren",
      },
    },
    closed: {
      clipPath: "circle(0vw at 56px 56px)",
      transition: {
        duration: 0.3,
      },
    },
  };

  const containerVariant = {
    initial: {
      transition: {
        delayChildren: 0.25,
        staggerChildren: 0.05,
        when: "beforeChildren",
      },
    },
    animate: {
      transition: {
        delayChildren: 0.22,
        staggerChildren: 0.05,
        when: "beforeChildren",
      },
    },
  };
  const exitContainerVariant = {
    initial: {
      transition: { staggerChildren: 0.05, when: "beforeChildren" },
    },
    animate: {
      transition: { staggerChildren: 0.05, when: "beforeChildren" },
    },
  };
  const childVariants = {
    initial: {
      y: -30,
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.52,
      },
    },
  };
  const exitVariants = {
    animate: {
      x: 30,
      opacity: 0,
      transition: {
        duration: 0.52,
      },
    },
  };

  return (
    <motion.nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: 100 + "%",
        boxSizing: "border-box",
        zIndex: "10",
      }}
    >
      <motion.div
        className="helloNavMobile"
        style={{
          width: "calc(100vw - (100vw - 100%))",
          height: 100 + "vh",
          background: bgColor,
          position: "absolute",
          top: 0,
          left: 0,
        }}
        variants={sidebar}
      >
        <MotionBox
          mt={12}
          variants={isOpen ? containerVariant : exitContainerVariant}
          initial="initial"
          animate="animate"
          display={"flex"}
          flexDirection={"column"}
          gap={4}
          p={12}
          pt={20}
        >
          {links.map((link, index) => (
            <MotionBox
              onClick={() => toggleOpen(false)}
              variants={isOpen ? childVariants : exitVariants}
              key={linkKey + index}
            >
              <RouterLink
                fontSize={"2xl"}
                isExternal={link.isExternal}
                link={link.link}
                color={linkColor}
              >
                {link.linkText}
              </RouterLink>
            </MotionBox>
          ))}
        </MotionBox>
        <MotionBox
          variants={isOpen ? containerVariant : exitContainerVariant}
          initial="initial"
          animate="animate"
          display={"flex"}
          flexDirection={"column"}
          gap={4}
          px={12}
        >
          {links1.map((link, index) => (
            <MotionBox
              onClick={() => toggleOpen(false)}
              variants={isOpen ? childVariants : exitVariants}
              key={linkKey1 + index}
            >
              <RouterLink
                fontSize={"md"}
                isExternal={link.isExternal}
                link={link.link}
                color={linkColor}
              >
                {link.linkText}
              </RouterLink>
            </MotionBox>
          ))}
        </MotionBox>
      </motion.div>
      <MenuToggle toggle={() => toggleOpen()} />
    </motion.nav>
  );
}
