import { Box, Container, Flex, Heading } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React from 'react';
import RouterLink from '../DefinitionComponents/Utility/Routing/RouterLink';

export default function Footer({
    links,
    linkKey,
    links1,
    linkKey1,
    links3,
    linkKey3,
    links2,
    linkKey2,
    textColor,
    bgColor,
}) {
    const MotionBox = motion(Box);
    const containerVariants = {
        initial: {
            width: '0%',
            opacity: 0,
        },
        animate: {
            width: '100%',
            opacity: 1,
            transition: {
                staggerChildren: 0.1,
                duration: 0.3,
                when: 'beforeChildren',
            },
        },
    };
    const childVariants = {
        initial: {
            x: 10,
            opacity: 0,
        },
        animate: {
            x: 0,
            opacity: 1,
            transition: {
                duration: 0.4,
            },
        },
    };

    const date2 = new Date();

    console.log('d : ' + date2);
    const date1 = date2.getFullYear();
    console.log('d2 : ' + date1);

    return (
        <Container
            as='footer'
            w='100%'
            maxW='100%'
            p={{ base: 2, lg: 24 }}
            bg={bgColor}>
            <Container variant='layoutContainer'>
                <Flex
                    alignItems='start'
                    flexWrap={'wrap'}>
                    <Flex
                        flexWrap={'wrap'}
                        minW={{ base: '100%', md: '24px' }}
                        my={{ base: '3rem', lg: '0' }}
                        justifyContent={{ base: 'center', md: 'start' }}>
                        <RouterLink
                            isExternal={false}
                            link={'/'}>
                            <svg
                                width='25'
                                height='60'
                                viewBox='0 0 25 60'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    fillRule='evenodd'
                                    clipRule='evenodd'
                                    d='M10.7466 40.5956L10.7444 59.9142C10.7444 59.9142 5e-05 50.2483 5e-05 40.5956C5e-05 33.0119 5e-05 14.3762 5e-05 14.3762H10.7496L10.7478 30.0762L5.92815 30.3703V32.5036H10.7475L10.7469 38.1682L5.92815 38.4622V40.5956H10.7466ZM14.1631 38.168L18.9818 38.4603V40.5956H14.1635L14.1657 59.9142C14.1657 59.9142 24.91 50.251 24.91 40.5956C24.91 33.0119 24.91 14.3762 24.91 14.3762H14.1604L14.1622 30.0761L18.9818 30.3692V32.5036H14.1625L14.1631 38.168ZM24.91 2.90579L12.5003 8.4962L0 2.90579V11.4362H24.91V2.90579ZM12.5003 5.81107C11.6597 5.81107 10.955 5.54132 10.3863 5.00231C9.8176 4.46281 9.53325 3.77603 9.53325 2.94248C9.53325 2.01074 9.8176 1.28727 10.3863 0.772066C10.955 0.257355 11.6597 0 12.5003 0C13.341 0 14.0456 0.257355 14.6143 0.772066C15.183 1.28727 15.4674 2.01074 15.4674 2.94248C15.4674 3.77603 15.183 4.46281 14.6143 5.00231C14.0456 5.54132 13.341 5.81107 12.5003 5.81107Z'
                                    fill='white'
                                />
                            </svg>
                        </RouterLink>
                    </Flex>
                    <Flex
                        alignItems='start'
                        flexWrap={'wrap'}
                        minW={{ base: '100%', lg: '30%' }}>
                        <MotionBox
                            flex='1'
                            ml={12}
                            variants={containerVariants}
                            initial='initial'
                            animate='animate'
                            pb={4}
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'flex-end'}>
                            {links.map((link, index) => (
                                <MotionBox
                                    variants={childVariants}
                                    key={linkKey + index}>
                                    <RouterLink
                                        isExternal={link.isExternal}
                                        link={link.link}
                                        color='brand.white'>
                                        {link.linkText}
                                    </RouterLink>
                                </MotionBox>
                            ))}
                        </MotionBox>

                        <MotionBox
                            flex='1'
                            ml={12}
                            variants={containerVariants}
                            initial='initial'
                            animate='animate'
                            pb={4}
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'flex-end'}>
                            {links1.map((link, index) => (
                                <MotionBox
                                    variants={childVariants}
                                    key={linkKey1 + index}>
                                    <RouterLink
                                        fontSize={'xs'}
                                        isExternal={link.isExternal}
                                        link={link.link}
                                        color='brand.white'>
                                        {link.linkText}
                                    </RouterLink>
                                </MotionBox>
                            ))}
                        </MotionBox>
                    </Flex>
                    <Flex
                        alignItems='start'
                        flexWrap={'wrap'}
                        minW={{ base: '100%', lg: '30%' }}>
                        <MotionBox
                            flex='1'
                            ml={12}
                            variants={containerVariants}
                            initial='initial'
                            animate='animate'
                            pb={4}
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'flex-end'}>
                            {links2.map((link, index) => (
                                <MotionBox
                                    variants={childVariants}
                                    key={linkKey2 + index}>
                                    <RouterLink
                                        fontSize={'xs'}
                                        isExternal={link.isExternal}
                                        link={link.link}
                                        color='brand.white'>
                                        {link.linkText}
                                    </RouterLink>
                                </MotionBox>
                            ))}
                        </MotionBox>

                        <MotionBox
                            flex='1'
                            ml={12}
                            variants={containerVariants}
                            initial='initial'
                            animate='animate'
                            pb={4}
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'flex-end'}>
                            {links3.map((link, index) => (
                                <MotionBox
                                    variants={childVariants}
                                    key={linkKey3 + index}>
                                    <RouterLink
                                        fontSize={'xs'}
                                        isExternal={link.isExternal}
                                        link={link.link}
                                        color='brand.white'>
                                        {link.linkText}
                                    </RouterLink>
                                </MotionBox>
                            ))}
                        </MotionBox>
                    </Flex>
                </Flex>

                <Flex>
                    <Heading
                        pl={{ base: 4, md: 8, lg: 12 }}
                        mt={{ base: 4, md: 8, lg: 12 }}
                        pb={8}
                        size='xs'
                        fontWeight='bold'
                        color='brand.white'>
                        © {date1} tokentus Investment AG · All rights reserved.
                    </Heading>
                </Flex>
            </Container>
        </Container>
    );
}
