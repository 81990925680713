/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import "./layout.css";
import CookieConsent, { Cookies } from "react-cookie-consent";
import Navigation from "./Navigation";
import Footer from "./FooterEl";
const Layout = ({ children }) => {
  return (
    <>
      <Navigation
        navBg="brand.black"
        linkKey="nav-link"
        links={[
          {
            linkText: "Home",
            link: "/",
            isExternal: false,
          },

          {
            linkText: "Targets",
            link: "/targets",
            isExternal: false,
          },
          {
            linkText: "Investors",
            link: "/investors",
            isExternal: false,
          },
          {
            linkText: "Portfolio",
            link: "/portfolio",
            isExternal: false,
          },
          {
            linkText: "Team",
            link: "/team",
            isExternal: false,
          },
          {
            linkText: "Investor Relations",
            link: "/investorrelations",
            isExternal: false,
          },

          {
            linkText: "Blog",
            link: "/blog",
            isExternal: false,
          },
          {
            linkText: "Knowledge Blocks",
            link: "/knowledgeblocks",
            isExternal: false,
          },
          // {
          //   linkText: "Hauptversammlung 2024",
          //   link: "/hv-2024",
          //   isExternal: false,
          // },
        ]}
        linkKey1="nav-info"
        links1={[
          {
            linkText: "Contact",
            link: "/contact",
            isExternal: false,
          },
          {
            linkText: "Disclaimer",
            link: "/disclaimer",
            isExternal: false,
          },

          {
            linkText: "Privacy",
            link: "/privacy",
            isExternal: false,
          },
        ]}
      />

      <main>{children}</main>

      <Footer
        linkKey="nav-link"
        links={[
          {
            linkText: "Home",
            link: "/",
            isExternal: false,
          },

          {
            linkText: "Targets",
            link: "/targets",
            isExternal: false,
          },
          {
            linkText: "Investors",
            link: "/investors",
            isExternal: false,
          },
          {
            linkText: "Portfolio",
            link: "/portfolio",
            isExternal: false,
          },
          {
            linkText: "Team",
            link: "/team",
            isExternal: false,
          },
          {
            linkText: "Investor Relations",
            link: "/investorrelations",
            isExternal: false,
          },
          {
            linkText: "Investor Mailing",
            link: "/investormailing",
            isExternal: false,
          },
          {
            linkText: "Blog",
            link: "/blog",
            isExternal: false,
          },
          {
            linkText: "Knowledge Blocks",
            link: "/knowledgeblocks",
            isExternal: false,
          },
        ]}
        linkKey1="nav-linkpersonal"
        links1={[
          {
            linkText: "Team",
            link: "/team",
            isExternal: false,
          },
          {
            linkText: "Oliver Michel",
            link: "/team/oliver-michel",
            isExternal: false,
          },
          {
            linkText: "Sarah Payne",
            link: "/team/sarah-payne",
            isExternal: false,
          },
          {
            linkText: "Benedikt Schulz",
            link: "/team/benedikt-schulz",
            isExternal: false,
          },
          {
            linkText: "Mona Tiesler",
            link: "/team/mona-tiesler",
            isExternal: false,
          },
        ]}
        linkKey2="nav-linportfolio"
        links2={[
          {
            linkText: "Portfolio",
            link: "/portfolio",
            isExternal: false,
          },
          {
            linkText: "Adhara",
            link: "/targets/adhara",
            isExternal: false,
          },

          {
            linkText: "BCB Group",
            link: "/targets/bcb",
            isExternal: false,
          },
          {
            linkText: "Blink Labs",
            link: "/targets/blinklabs",
            isExternal: false,
          },

          {
            linkText: "Breach",
            link: "/targets/breach",
            isExternal: false,
          },

          {
            linkText: "Estating",
            link: "/targets/estating",
            isExternal: false,
          },
          {
            linkText: "Faction",
            link: "/targets/faction",
            isExternal: false,
          },
          {
            linkText: "Frachtis",
            link: "/targets/frachtis",
            isExternal: false,
          },

          {
            linkText: "gumi Cryptos",
            link: "/targets/gumi",
            isExternal: false,
          },
          {
            linkText: "Hydra Ventures",
            link: "/targets/hydraventures",
            isExternal: false,
          },
          {
            linkText: "iVE.ONE",
            link: "/targets/iveone",
            isExternal: false,
          },
          {
            linkText: "Intract",
            link: "/targets/intract",
            isExternal: false,
          },
          {
            linkText: "Jiritsu",
            link: "/targets/jiritsu",
            isExternal: false,
          },
          {
            linkText: "Lava Network",
            link: "/targets/lavanet",
            isExternal: false,
          },

          {
            linkText: "Nayms",
            link: "/targets/nayms",
            isExternal: false,
          },

          {
            linkText: "Ownera",
            link: "/targets/ownera",
            isExternal: false,
          },
          {
            linkText: "Qredo",
            link: "/targets/qredo",
            isExternal: false,
          },
          {
            linkText: "Playground",
            link: "/targets/playground",
            isExternal: false,
          },
          {
            linkText: "Polysign",
            link: "/targets/polysign",
            isExternal: false,
          },
          {
            linkText: "Ripple",
            link: "/targets/ripple",
            isExternal: false,
          },
          {
            linkText: "SwissBorg",
            link: "/targets/swissborg",
            isExternal: false,
          },
          {
            linkText: "Spacemesh",
            link: "/targets/spacemesh",
            isExternal: false,
          },

          // {
          //   linkText: 'Trality',
          //   link: '/targets/trality',
          //   isExternal: false,
          // },
          {
            linkText: "Transak",
            link: "/targets/transak",
            isExternal: false,
          },
          {
            linkText: "XRP",
            link: "/targets/xrp",
            isExternal: false,
          },
        ]}
        linkKey3="nav-info"
        links3={[
          {
            linkText: "Contact",
            link: "/contact",
            isExternal: false,
          },
          {
            linkText: "Disclaimer",
            link: "/disclaimer",
            isExternal: false,
          },

          {
            linkText: "Privacy",
            link: "/privacy",
            isExternal: false,
          },
        ]}
        bgColor={"brand.darkgray"}
        textColor={"white"}
      />
    </>
  );
};

export default Layout;
