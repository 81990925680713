import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    heading: "TokentusNormal, sans-serif",
    body: "TokentusNormal, sans-serif",
  },
  sizes: {
    container: {
      xxl: "1600px",
      xl: "1440px",
    },
  },
  breakpoints: {
    xxl: "1600px",
    xl: "1440px",
  },

  lineHeights: {
    undercut: "0.8",
    normal: "normal",
    none: 1,
    shorter: 1.25,
    short: 1.375,
    base: 1.5,
    tall: 1.625,
    taller: "2",
    3: ".75rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    7: "1.75rem",
    8: "2rem",
    9: "2.25rem",
    10: "2.5rem",
  },
  letterSpacings: {
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0",
    wide: "0.025em",
    wider: "0.05em",
    widest: "0.1em",
  },
  components: {
    Heading: {
      baseStyle: {
        fontWeight: "normal",
        lineHeight: "undercut",
        letterSpacing: "tight",
      },
    },
    Text: {
      baseStyle: {
        fontWeight: "normal",
        lineHeight: "shorter",
        letterSpacing: "tight",
      },
    },

    Button: {
      baseStyle: {
        borderRadius: "none",
      },
      variants: {
        solid: {
          borderRadius: "none",
          paddingRight: "3rem",
          display: "block",
          fontSize: "xs",
          letterSpacing: "wide",
          transition: "all .25s ease-in-out",
          _hover: {
            background: "brand.black",
            color: "brand.white",
            transition: "all .125s ease-in-out",
          },
        },
        ghost: {
          borderRadius: "none",
          paddingRight: "3rem",
          display: "block",
          fontSize: "xs",
          letterSpacing: "wider",
          transition: "all .25s ease-in-out",
          border: "1px solid",
          _hover: {
            background: "transparent",
            transition: "all .125s ease-in-out",
          },
        },
      },
    },
    Container: {
      variants: {
        layoutContainer: {
          maxW: { base: "calc(100% - 2rem)", lg: "8xl" },
          boxSizing: "border-box",
          py: 0,
          px: 0,
        },
      },
    },
    Box: {
      variants: {
        pageButton: {
          _hover: {
            background: "brand.green",
          },
        },
      },
    },

    Link: {
      baseStyle: {
        _hover: {
          textDecoration: "none",
        },
      },
      variants: {
        navlink: {
          textDecoration: "none",
          _hover: { textDecoration: "underline" },
        },
      },
    },
  },
  colors: {
    brand: {
      blue: "#1B0F67",
      green: "#00ff99",
      black: "#000000",
      darkgray: "#202025",
      meddarkgray: "#3c3c3c",
      med2gray: "#999999",
      medgray: "#aaaaaa",
      medlightgray: "#e6e6e6",
      lightgray: "#f9f9f9",
      white: "#ffffff",
      warn: "#ff0000",
    },
  },
});

export default theme;
