import {
  Box,
  Container, useColorModeValue
} from "@chakra-ui/react";
import {
  motion,
  useTransform, useViewportScroll
} from "framer-motion";
import React from "react";
import RouterLink from "../DefinitionComponents/Utility/Routing/RouterLink";
import MobileMenu from "./MobileMenu";

export default function Navigation({ links, linkKey, links1,
  linkKey1  }) {

  //const navBg = useColorModeValue('transparent', 'transparent');
  const navBorder = useColorModeValue("white", "brand.blue");
  const linkColor = useColorModeValue("white", "#1B0F67");
  const navBg = useColorModeValue("#000","white" );
  const MotionBox = motion(Box);

  const containerVariants = {
    initial: {
      width: "0%",
      opacity: 0,
    },
    animate: {
      width: "100%",
      opacity: 1,
      transition: { staggerChildren: 0.1, duration: 0, when: "beforeChildren" },
    },
  };

  const { scrollY } = useViewportScroll();
  const opacityEl = useTransform(scrollY, [0, 64, 256], ["0.0", "0.0", "0.9"]);

  const childVariants = {
    initial: {
      scale: 0.8,
      y: 10,
      x: 10,
      opacity: 0, 
    },
    animate: {
      scale: 1,
      y: 0,
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.4,
      },
    },
  };
  return (
    <>
      <MobileMenu links={links} linkKey={linkKey}   links1={links1} linkKey1={linkKey1} />

     
      <MotionBox
        as="nav"
        boxSizing={"border-box"}
        position={"fixed"}
        top={0}
        left={0}
        zIndex={10}
        w="100%" h='64px'
      >
        <MotionBox 
          w={{base:'0',md:"100%"}}
          zIndex="-1"
          display={{base:'none',md:'block'}}
          minH="64px"
          position="absolute" h='64px'
          style={{ opacity: opacityEl }}
          background={navBg}
        ></MotionBox>
        <Container
          h={"64px"}
          zIndex="222"
          display={{ base: "none", lg: "flex" }}
          pb={4}
          variant="layoutContainer"
          alignItems={"center"}
          px={{ base: "2rem", lg: "px" }}
          justifyContent={"space-between"}
          gap={8}
        >
          <MotionBox pt={6} pl={4}>
            <RouterLink isExternal={false} link={"/"}>
              <svg
                width="100"
                height="50"
                viewBox="0 0 112 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M41.4085 34.0001V31.7681H40.2925C39.6625 31.7681 39.5725 31.3901 39.5725 30.9941V26.0081H41.4265V23.8481H39.5725V20.2661H36.8365V23.8481H35.3965V26.0081H36.8365V31.8761C36.8365 33.3161 37.7005 34.0001 39.1945 34.0001H41.4085Z"
                  fill={linkColor}
                />
                <path
                  d="M47.3627 34.2161C50.5307 34.2161 52.6187 32.0561 52.6187 28.8701C52.6187 25.7741 50.4947 23.6321 47.3627 23.6321C44.2307 23.6321 42.0887 25.7741 42.0887 28.8701C42.0887 32.0741 44.2307 34.2161 47.3627 34.2161ZM47.3627 31.8581C45.9047 31.8581 44.8967 30.6881 44.8967 28.8701C44.8967 27.1421 45.8867 25.9721 47.3627 25.9721C48.8387 25.9721 49.7927 27.1781 49.7927 28.8701C49.7927 30.6521 48.8387 31.8581 47.3627 31.8581Z"
                  fill={linkColor}
                />
                <path
                  d="M56.6012 34.0001V29.0501L60.4532 34.0001H63.7472L59.1392 28.2401L63.4772 23.8481H60.0572L56.6012 27.5021V20.2661H53.8652V34.0001H56.6012Z"
                  fill={linkColor}
                />
                <path
                  d="M68.6279 34.2161C71.4179 34.2161 72.9839 32.5241 73.5599 30.5441H70.8059C70.5179 31.2641 69.7799 31.8581 68.7539 31.8581C67.8539 31.8581 67.1339 31.4801 66.6839 30.7421C66.4679 30.4361 66.2879 29.9501 66.2339 29.5001H73.7219V28.9601C73.7219 25.1801 71.1839 23.6321 68.5379 23.6321C64.9739 23.6321 63.3899 26.3861 63.3899 28.9961C63.3899 32.0021 65.4599 34.2161 68.6279 34.2161ZM66.3239 27.5021C66.3779 27.2681 66.4679 26.9081 66.7559 26.5841C67.2239 26.0081 67.8899 25.7561 68.6459 25.7561C69.3839 25.7561 70.0679 26.0081 70.5179 26.5841C70.8059 26.8901 70.8959 27.2501 70.9499 27.5021H66.3239Z"
                  fill={linkColor}
                />
                <path
                  d="M77.6738 34.0001V28.5641C77.6738 27.4481 77.8178 26.8181 78.1418 26.4221C78.4478 26.0261 78.9338 25.8461 79.5998 25.8461C80.1398 25.8461 80.5178 25.9721 80.7878 26.2601C81.1118 26.6201 81.2558 27.1961 81.2558 28.2041V34.0001H83.9738V28.2401C83.9738 27.1061 83.9738 26.2421 83.5598 25.3601C83.1458 24.4241 82.2638 23.6321 80.3378 23.6321C79.2578 23.6321 78.4118 23.9561 77.6738 25.0361V23.8481H74.9378V34.0001H77.6738Z"
                  fill={linkColor}
                />
                <path
                  d="M90.7854 34.0001V31.7681H89.6694C89.0394 31.7681 88.9494 31.3901 88.9494 30.9941V26.0081H90.8034V23.8481H88.9494V20.2661H86.2134V23.8481H84.7734V26.0081H86.2134V31.8761C86.2134 33.3161 87.0774 34.0001 88.5714 34.0001H90.7854Z"
                  fill={linkColor}
                />
                <path
                  d="M95.5667 34.2161C96.7727 34.2161 97.5647 33.7841 98.2487 32.8121V34.0001H100.985V23.8481H98.2487V29.2841C98.2487 30.3641 98.1227 31.0121 97.7627 31.4261C97.4567 31.8041 97.0067 32.0021 96.3227 32.0021C95.7647 32.0021 95.3867 31.8581 95.1347 31.5881C94.8107 31.2281 94.6667 30.6521 94.6667 29.6441V23.8481H91.9487V29.6081C91.9487 31.1561 92.0207 31.8041 92.3447 32.4881C92.8847 33.6221 93.9467 34.2161 95.5667 34.2161Z"
                  fill={linkColor}
                />
                <path
                  d="M107.109 34.2161C109.773 34.2161 111.375 32.8661 111.375 30.8861C111.375 28.5281 109.179 28.1141 107.397 27.7901C106.191 27.5741 105.165 27.3761 105.165 26.6201C105.165 26.0621 105.705 25.7201 106.569 25.7201C107.451 25.7201 108.261 26.1161 108.549 27.1421H111.123C110.763 24.8921 108.999 23.6321 106.569 23.6321C104.463 23.6321 102.573 24.5321 102.573 26.6921C102.573 29.0321 104.697 29.4641 106.443 29.8061C107.649 30.0581 108.693 30.2741 108.693 31.0661C108.693 31.7321 107.991 32.1101 107.073 32.1101C106.029 32.1101 105.147 31.6061 104.823 30.5441H102.159C102.501 33.2261 104.877 34.2161 107.109 34.2161Z"
                  fill={linkColor}
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.7466 40.5956L10.7444 59.9142C10.7444 59.9142 5e-05 50.2483 5e-05 40.5956C5e-05 33.0119 5e-05 14.3762 5e-05 14.3762H10.7496L10.7478 30.0762L5.92815 30.3703V32.5036H10.7475L10.7469 38.1682L5.92815 38.4622V40.5956H10.7466ZM14.1631 38.168L18.9818 38.4604V40.5956H14.1635L14.1657 59.9142C14.1657 59.9142 24.91 50.251 24.91 40.5956C24.91 33.0119 24.91 14.3762 24.91 14.3762H14.1604L14.1622 30.0761L18.9818 30.3692V32.5036H14.1625L14.1631 38.168ZM24.91 2.90579L12.5003 8.4962L0 2.90579V11.4362H24.91V2.90579ZM12.5003 5.81107C11.6597 5.81107 10.955 5.54132 10.3863 5.00231C9.8176 4.46281 9.53325 3.77603 9.53325 2.94248C9.53325 2.01074 9.8176 1.28727 10.3863 0.772066C10.955 0.257355 11.6597 0 12.5003 0C13.341 0 14.0456 0.257355 14.6143 0.772066C15.183 1.28727 15.4674 2.01074 15.4674 2.94248C15.4674 3.77603 15.183 4.46281 14.6143 5.00231C14.0456 5.54132 13.341 5.81107 12.5003 5.81107Z"
                  fill={linkColor}
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.92815 30.3703L10.7478 30.0762L10.7496 14.3762H5e-05V40.5956C5e-05 48.4302 7.07816 56.2736 9.74446 58.9502C10.3632 59.5713 10.7444 59.9142 10.7444 59.9142L10.7466 40.5956H5.92815V38.4622L10.7469 38.1682L10.7475 32.5036H5.92815V30.3703ZM9.74739 33.5036H4.92815V29.4295L9.74791 29.1353L9.74949 15.3762H1.00005V40.5956C1.00005 45.0888 3.52136 49.6991 6.17551 53.2791C7.46835 55.0229 8.76315 56.4832 9.74463 57.5161L9.74648 41.5956H4.92815V37.5214L9.74696 37.2273L9.74739 33.5036ZM19.9818 37.5192V41.5956H15.1636L15.1654 57.5165C16.1469 56.4838 17.4417 55.0238 18.7346 53.2801C21.3887 49.7005 23.91 45.0902 23.91 40.5956V15.3762H15.1606L15.1621 29.135L19.9818 29.4281V33.5036H15.1626L15.163 37.2268L19.9818 37.5192ZM14.1631 38.168L18.9818 38.4604V40.5956H14.1635L14.1657 59.9142C14.1657 59.9142 14.5469 59.5714 15.1656 58.9504C17.8319 56.2744 24.91 48.4324 24.91 40.5956V14.3762H14.1604L14.1622 30.0761L18.9818 30.3692V32.5036H14.1625L14.1631 38.168ZM12.5018 9.59231L1 4.44846V10.4362H23.91V4.45306L12.5018 9.59231ZM24.91 2.90579V11.4362H0V2.90579L12.5003 8.4962L24.91 2.90579ZM11.0742 4.27654C11.4429 4.626 11.8953 4.81107 12.5003 4.81107C13.1052 4.81107 13.5576 4.62602 13.9264 4.27651C14.2821 3.93892 14.4674 3.52103 14.4674 2.94248C14.4674 2.22757 14.2583 1.79887 13.9429 1.51317C13.5764 1.1816 13.1185 1 12.5003 1C11.882 1 11.4241 1.18163 11.0577 1.51317C10.7423 1.79887 10.5332 2.22757 10.5332 2.94248C10.5332 3.52105 10.7185 3.93895 11.0742 4.27654ZM10.3863 5.00231C10.955 5.54132 11.6597 5.81107 12.5003 5.81107C13.341 5.81107 14.0456 5.54132 14.6143 5.00231C15.183 4.46281 15.4674 3.77603 15.4674 2.94248C15.4674 2.01074 15.183 1.28727 14.6143 0.772066C14.0456 0.257355 13.341 0 12.5003 0C11.6597 0 10.955 0.257355 10.3863 0.772066C9.8176 1.28727 9.53325 2.01074 9.53325 2.94248C9.53325 3.77603 9.8176 4.46281 10.3863 5.00231Z"
                  fill={linkColor}
                />
              </svg>
            </RouterLink>
          </MotionBox>
          <MotionBox
            variants={containerVariants}
            initial="initial"
            animate="animate"
            borderColor={navBorder}
            pt={2}
            w={"100%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"flex-end"}
          >
            <MotionBox
              w={"100%"}
              display={"flex"}
              justifyContent={"flex-end"}
              gap={8}
              px={12}
              boxSizing={"border-box"}
            >
              {links.map((link, index) => (
                <MotionBox variants={childVariants} key={linkKey + index}>
                  <RouterLink
                    variant="navlink"
                    isExternal={link.isExternal}
                    link={link.link}
                    color={linkColor}
                  >
                    {link.linkText}
                  </RouterLink>
                </MotionBox>
              ))}
            </MotionBox>
          </MotionBox>
        </Container>
      </MotionBox>
    </>
  );
}
