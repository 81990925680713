import React from 'react';
import { Global } from '@emotion/react'

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'TokentusNormal';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url('/static/fonts/OpenSauceOne-Regular.woff2') format('woff2'), url('/static/fonts/OpenSauceOne-Regular.woff') format('woff');
      }
      `}
  />
)

export default Fonts