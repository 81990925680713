import React, { useEffect } from "react";

import { motion } from "framer-motion";
import { Button ,useColorModeValue,useColorMode} from "@chakra-ui/react";

const Path = props => (
  <motion.path
  
    strokeWidth="2"
    stroke="#ffffff"
    strokeLinecap="round"
    {...props}
  />
);

export const MenuToggle = ({ toggle }) => {

  return(
 
  <Button
    onClick={toggle}
    borderRadius={200}
    h={50}
    w={50} p={0}
    variant="ghost"
    borderColor={useColorModeValue("brand.blue", "brand.green")} 
    position={'absolute'}
    top={8}
    left={8}
    display={{ base: 'inline-flex', lg: 'none' }}
    _focus={{ outline: 'none' }}
    _hover={{ bg:useColorModeValue("brand.blue", "brand.blue") }}
  >
    <svg pointerEvents="none" position="relative" top="0" width="23" height="23" viewBox="0 0 23 23">
      <Path
        variants={{
          closed: { d: "M 2 2.5 L 20 2.5", stroke: useColorModeValue('var(--chakra-colors-brand-white)', 'var(--chakra-colors-brand-green)') },
          open: { d: "M 3 16.5 L 17 2.5", stroke: useColorModeValue('var(--chakra-colors-brand-white)', 'var(--chakra-colors-brand-white)')  }
        }}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1, stroke: useColorModeValue('var(--chakra-colors-brand-white)', 'var(--chakra-colors-brand-green)') },
          open: { opacity: 0 ,stroke: useColorModeValue('var(--chakra-colors-brand-white)', 'var(--chakra-colors-brand-white)')  }
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        variants={{
          closed: { d: "M 2 16.346 L 20 16.346",stroke: useColorModeValue('var(--chakra-colors-brand-white)', 'var(--chakra-colors-brand-green)')  },
          open: { d: "M 3 2.5 L 17 16.346" ,stroke: useColorModeValue('var(--chakra-colors-brand-white)', 'var(--chakra-colors-brand-white)')  }
        }}
      />
    </svg>
  </Button>
)};
