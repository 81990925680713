exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-adhoc-js": () => import("./../../../src/pages/adhoc.js" /* webpackChunkName: "component---src-pages-adhoc-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cryptos-js": () => import("./../../../src/pages/cryptos.js" /* webpackChunkName: "component---src-pages-cryptos-js" */),
  "component---src-pages-directorsdealings-copy-js": () => import("./../../../src/pages/directorsdealings-copy.js" /* webpackChunkName: "component---src-pages-directorsdealings-copy-js" */),
  "component---src-pages-directorsdealings-js": () => import("./../../../src/pages/directorsdealings.js" /* webpackChunkName: "component---src-pages-directorsdealings-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-hv-2022-js": () => import("./../../../src/pages/hv-2022.js" /* webpackChunkName: "component---src-pages-hv-2022-js" */),
  "component---src-pages-hv-2023-js": () => import("./../../../src/pages/hv-2023.js" /* webpackChunkName: "component---src-pages-hv-2023-js" */),
  "component---src-pages-hv-2024-js": () => import("./../../../src/pages/hv-2024.js" /* webpackChunkName: "component---src-pages-hv-2024-js" */),
  "component---src-pages-index-copy-js": () => import("./../../../src/pages/index-copy.js" /* webpackChunkName: "component---src-pages-index-copy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investormailing-js": () => import("./../../../src/pages/investormailing.js" /* webpackChunkName: "component---src-pages-investormailing-js" */),
  "component---src-pages-investorrelations-js": () => import("./../../../src/pages/investorrelations.js" /* webpackChunkName: "component---src-pages-investorrelations-js" */),
  "component---src-pages-investors-js": () => import("./../../../src/pages/investors.js" /* webpackChunkName: "component---src-pages-investors-js" */),
  "component---src-pages-knowledgeblocks-js": () => import("./../../../src/pages/knowledgeblocks.js" /* webpackChunkName: "component---src-pages-knowledgeblocks-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-targets-js": () => import("./../../../src/pages/targets.js" /* webpackChunkName: "component---src-pages-targets-js" */),
  "component---src-pages-team-benedikt-schulz-js": () => import("./../../../src/pages/team/benedikt-schulz.js" /* webpackChunkName: "component---src-pages-team-benedikt-schulz-js" */),
  "component---src-pages-team-index-js": () => import("./../../../src/pages/team/index.js" /* webpackChunkName: "component---src-pages-team-index-js" */),
  "component---src-pages-team-mona-tiesler-js": () => import("./../../../src/pages/team/mona-tiesler.js" /* webpackChunkName: "component---src-pages-team-mona-tiesler-js" */),
  "component---src-pages-team-oliver-michel-js": () => import("./../../../src/pages/team/oliver-michel.js" /* webpackChunkName: "component---src-pages-team-oliver-michel-js" */),
  "component---src-pages-team-sarah-payne-js": () => import("./../../../src/pages/team/sarah-payne.js" /* webpackChunkName: "component---src-pages-team-sarah-payne-js" */),
  "component---src-templates-targetpage-js": () => import("./../../../src/templates/targetpage.js" /* webpackChunkName: "component---src-templates-targetpage-js" */)
}

